@font-face {
    font-family: Gilroy;
    src: url(../assets/font/Gilroy-Regular.ttf);
}

@font-face {
    font-family: Gilroy;
    src: url(../assets/font/Gilroy-Medium.ttf);
    font-weight: 600;
}

@font-face {
    font-family: Gilroy;
    src: url(../assets/font/Gilroy-Heavy.ttf);
    font-weight: 700;
}


